var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5"},[(this.loading === true)?_c('v-skeleton-loader',{attrs:{"type":"heading","min-height":"57"}}):_vm._e(),_c('v-chip',{attrs:{"label":"","color":"green lighten-4","close":"","close-icon":"mdi-check"}},[_vm._v("Reconciled")]),_c('h1',{staticClass:"pt-3"},[_vm._v("Inter Account Transfer")])],1)],1)],1),_c('v-col')],1),_c('v-row',[_c('v-col',{staticClass:"mb-3",attrs:{"sm":"3","md":"3"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.value.transaction_type === "Cash In" ? "To" : "From")+" ")])])],1),_c('v-alert',{attrs:{"prominent":"","color":_vm.value.transaction_type === 'Cash In'
        ? 'green lighten-4'
        : 'red lighten-5'}},[_c('v-row',_vm._l(([
          { header: 'Transaction #' },
          { header: 'Bank Account' },
          { header: 'Date' },
          { header: 'Amount' } ]),function(item){return _c('v-col',{key:item.header,attrs:{"sm":"3","md":"3"}},[_c('h4',[_vm._v(_vm._s(item.header))])])}),1),_c('v-row',_vm._l(([
          { name: _vm.value.transaction_number },
          { name: _vm.value.bank_account_name },
          { name: _vm.value.transaction_date },
          { name: _vm.formatAsCurrency('R', Math.abs(_vm.value.transaction_amount)) } ]),function(item){return _c('v-col',{key:item.name,attrs:{"sm":"3","md":"3"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-3",attrs:{"sm":"3","md":"3"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.value.transaction_type === "Cash In" ? "From" : "To")+" ")])])],1),_c('v-alert',{attrs:{"prominent":"","color":_vm.value.transaction_type === 'Cash In'
        ? 'red lighten-5'
        : 'green lighten-4'}},[_c('v-row',_vm._l(([
          { header: 'Transaction #' },
          { header: 'Bank Account' },
          { header: 'Date' },
          { header: 'Amount' } ]),function(item){return _c('v-col',{key:item.header,attrs:{"sm":"3","md":"3"}},[_c('h4',[_vm._v(_vm._s(item.header))])])}),1),_c('v-row',_vm._l(([
          { name: _vm.value.related_transaction_number },
          { name: _vm.value.related_transaction_bank },
          { name: _vm.value.transaction_date },
          { name: _vm.formatAsCurrency('R', Math.abs(_vm.value.transaction_amount)) } ]),function(item){return _c('v-col',{key:item.name,attrs:{"sm":"3","md":"3"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('v-row',{staticClass:"my-3"},[_c('v-col',[_c('v-divider')],1)],1),_c('v-card-actions',[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-spacer'),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Remove & Redo ")])]}}]),model:{value:(_vm.remove_and_redo_dialog),callback:function ($$v) {_vm.remove_and_redo_dialog=$$v},expression:"remove_and_redo_dialog"}},[_c('inter-account-transfer-remove-dialog',{attrs:{"remove_and_redo_dialog":this.remove_and_redo_dialog,"value":this.value},on:{"changeCancelDialog":function($event){return _vm.changeCancelDialog($event)}}})],1)],1),(this.loading === true)?_c('v-skeleton-loader',{attrs:{"type":"table","min-height":"100","max-height":"100"}}):_c('div')],1)}
var staticRenderFns = []

export { render, staticRenderFns }