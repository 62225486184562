<template>
  <v-container fluid>
    <v-row>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5">
            <!--Heading-->
            <v-skeleton-loader
              v-if="this.loading === true"
              type="heading"
              min-height="57"
            >
            </v-skeleton-loader>
            <v-chip label color="green lighten-4" close close-icon="mdi-check"
              >Reconciled</v-chip
            >
            <h1 class="pt-3">Inter Account Transfer</h1>
            <!--End of heading-->
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-col> </v-col>
    </v-row>
    <!--Transaction details-->
    <v-row>
      <v-col sm="3" md="3" class="mb-3"
        ><h3>
          {{ value.transaction_type === "Cash In" ? "To" : "From" }}
        </h3></v-col
      >
    </v-row>
    <v-alert
      prominent
      :color="
        value.transaction_type === 'Cash In'
          ? 'green lighten-4'
          : 'red lighten-5'
      "
    >
      <v-row>
        <v-col
          sm="3"
          md="3"
          v-for="item in [
            { header: 'Transaction #' },
            { header: 'Bank Account' },
            { header: 'Date' },
            { header: 'Amount' },
          ]"
          :key="item.header"
        >
          <h4>{{ item.header }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="3"
          md="3"
          v-for="item in [
            { name: value.transaction_number },
            { name: value.bank_account_name },
            { name: value.transaction_date },
            { name: formatAsCurrency('R', Math.abs(value.transaction_amount)) },
          ]"
          :key="item.name"
        >
          {{ item.name }}
        </v-col>
      </v-row>
    </v-alert>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="3" md="3" class="mb-3"
        ><h3>
          {{ value.transaction_type === "Cash In" ? "From" : "To" }}
        </h3></v-col
      >
    </v-row>
    <!--Transaction details-->
    <v-alert
      prominent
      :color="
        value.transaction_type === 'Cash In'
          ? 'red lighten-5'
          : 'green lighten-4'
      "
    >
      <v-row>
        <v-col
          sm="3"
          md="3"
          v-for="item in [
            { header: 'Transaction #' },
            { header: 'Bank Account' },
            { header: 'Date' },
            { header: 'Amount' },
          ]"
          :key="item.header"
        >
          <h4>{{ item.header }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="3"
          md="3"
          v-for="item in [
            { name: value.related_transaction_number },
            { name: value.related_transaction_bank },
            { name: value.transaction_date },
            { name: formatAsCurrency('R', Math.abs(value.transaction_amount)) },
          ]"
          :key="item.name"
        >
          {{ item.name }}
        </v-col>
      </v-row>
    </v-alert>
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-dialog v-model="remove_and_redo_dialog" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-spacer></v-spacer>
          <v-btn color="red" outlined v-bind="attrs" v-on="on">
            Remove & Redo
          </v-btn>
        </template>
        <inter-account-transfer-remove-dialog
          :remove_and_redo_dialog="this.remove_and_redo_dialog"
          :value="this.value"
          @changeCancelDialog="changeCancelDialog($event)"
        >
        </inter-account-transfer-remove-dialog>
      </v-dialog>
    </v-card-actions>
    <!--Skeleton loader-->
    <v-skeleton-loader
      v-if="this.loading === true"
      type="table"
      min-height="100"
      max-height="100"
    >
    </v-skeleton-loader>
    <!--End of skeleton loader-->
    <div v-else></div>
  </v-container>
</template>
<script>
import { formatAsCurrency } from "../../../composables/external";
export default {
  name: "InterAccountTransferSummary",
  props: ["value"],
  components: {
    InterAccountTransferRemoveDialog: () =>
      import("./InterAccountTransferRemoveDialog.vue"),
  },
  data() {
    return {
      remove_and_redo_dialog: false,
      loading: false,
    };
  },
  methods: {
    formatAsCurrency,
    changeCancelDialog(remove_and_redo_dialog) {
      this.remove_and_redo_dialog = remove_and_redo_dialog;
    },
  },
};
</script>
